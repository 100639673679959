import React, { useState } from 'react'
import { LazyLoadImage } from 'react-lazy-load-image-component'
import ReactModal from 'react-modal';
import CloseIcon from '@mui/icons-material/Close';
import gemlanding from "../../Assets/projects/gem/landing.webp"
import gemSs1 from "../../Assets/projects/gem/ss1.webp"
import gemSs2 from "../../Assets/projects/gem/ss2.webp"
import gemSs3 from "../../Assets/projects/gem/ss3.webp"
import gemSs4 from "../../Assets/projects/gem/ss4.webp"

import nutrfitlanding from "../../Assets/projects/nutri/landing.webp"
import nutrfitSs1 from "../../Assets/projects/nutri/ss1.webp"
import nutrfitSs2 from "../../Assets/projects/nutri/ss2.webp"
import nutrfitSs3 from "../../Assets/projects/nutri/ss3.webp"
import nutrfitSs4 from "../../Assets/projects/nutri/ss4.webp"

import tanzanitelanding from "../../Assets/projects/tanzanite/landing.webp"
import tanzaniteSs1 from "../../Assets/projects/tanzanite/ss1.webp"
import tanzaniteSs2 from "../../Assets/projects/tanzanite/ss2.webp"
import tanzaniteSs3 from "../../Assets/projects/tanzanite/ss3.webp"

import clientslanding from "../../Assets/projects/clients/landing.webp"
import clientsSs1 from "../../Assets/projects/clients/ss1.webp"
import clientsSs2 from "../../Assets/projects/clients/ss2.webp"
import clientsSs3 from "../../Assets/projects/clients/ss3.webp"

import weddinglanding from "../../Assets/projects/wedding/landing.webp"

import { Carousel } from 'react-carousel-minimal';
import Navbar from '../Navbar/Navbar';

const Projects = () => {
    const [gemproject, setGemproject] = useState(false);
    const [nutritionistproject, setNutritionistproject] = useState(false);
    const [Tanzaniteplusproject, setTanzaniteplusproject] = useState(false);
    const [clientsproject, setClientsproject] = useState(false);

    const gemData = [
        {
            image: gemlanding,
            caption: "Website Landing Page"
        },
        {
            image: gemSs1,
            caption: "Newsletter Subscription Popup"
        },
        {
            image: gemSs2,
            caption: "Contact Page"
        },
        {
            image: gemSs3,
            caption: "Search Box "
        },
        {
            image: gemSs4,
            caption: "Products Display page"
        }
    ];
    const nutrifitData = [
        {
            image: nutrfitlanding,
            caption: "Website Landing Page"
        },
        {
            image: nutrfitSs1,
            caption: "Goal Page"
        },
        {
            image: nutrfitSs2,
            caption: "Class Availibility Page"
        },
        {
            image: nutrfitSs3,
            caption: "About Gym trainer"
        },
        {
            image: nutrfitSs4,
            caption: "Contact page"
        }
    ];
    const clientsData = [
        {
            image: clientslanding,
            caption: "Website login Page"
        },
        {
            image: clientsSs1,
            caption: "Dashboard"
        },
        {
            image: clientsSs2,
            caption: "Register New Client Data"
        },
        {
            image: clientsSs3,
            caption: "Search Between Dates"
        }
    ];
    const tanzaniteData = [
        {
            image: tanzanitelanding,
            caption: "Website landing Page"
        },
        {
            image: tanzaniteSs1,
            caption: "About Page"
        },
        {
            image: tanzaniteSs2,
            caption: "Services Page"
        },
        {
            image: tanzaniteSs3,
            caption: "Contact Page"
        }
    ];
    return (
        <>
            <Navbar />
            <div className='bg-white dark:bg-black dark:text-white min-h-screen'>
                <h2 className='text-3xl text-center py-10'>My Projects</h2>
                <div class="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-3 gap-8 p-4">

                    <div class="group cursor-pointer relative">
                        <LazyLoadImage
                            src={gemlanding}
                            alt="Gem experience landing page screenshot"
                            class="border-black border w-full h-56 object-cover rounded-lg transition-transform transform scale-100 group-hover:scale-105"
                        />
                        <div class="absolute inset-0 flex flex-col gap-2 items-center justify-center opacity-0 group-hover:opacity-100 transition-opacity">
                            <a href='https://www.gem-experience.com/' class="bg-white text-gray-800 px-4 py-2 rounded-lg hover:bg-gray-200 transition-colors">
                                View live
                            </a>
                            <button class="bg-white text-gray-800 px-4 py-2 rounded-lg hover:bg-gray-200 transition-colors" onClick={() => setGemproject(true)}>
                                View screenshots
                            </button>
                        </div>
                    </div>
                    <div class="group cursor-pointer relative">
                        <LazyLoadImage
                            src={clientslanding}
                            alt="Clients portal landing page screenshot"
                            class="border-black border w-full h-56 object-cover rounded-lg transition-transform transform scale-100 group-hover:scale-105"
                        />
                        <div class="absolute inset-0 flex flex-col gap-2 items-center justify-center opacity-0 group-hover:opacity-100 transition-opacity">
                            {/* <button class="bg-white text-gray-800 px-4 py-2 rounded-lg hover:bg-gray-200 transition-colors">
                            View live
                        </button> */}
                            <button class="bg-white text-gray-800 px-4 py-2 rounded-lg hover:bg-gray-200 transition-colors" onClick={() => setClientsproject(true)}>
                                View screenshots
                            </button>
                        </div>
                    </div>
                    <div class="group cursor-pointer relative">
                        <LazyLoadImage
                            src={nutrfitlanding}
                            alt="nutritionist leena website landing page"
                            class="border-black border w-full h-56 object-cover rounded-lg transition-transform transform scale-100 group-hover:scale-105"
                        />
                        <div class="absolute inset-0 flex flex-col gap-2 items-center justify-center opacity-0 group-hover:opacity-100 transition-opacity">
                            <a href="https://nutritionistleena.vercel.app/" class="bg-white text-gray-800 px-4 py-2 rounded-lg hover:bg-gray-200 transition-colors">
                                View live
                            </a>
                            <button class="bg-white text-gray-800 px-4 py-2 rounded-lg hover:bg-gray-200 transition-colors" onClick={() => setNutritionistproject(true)}>
                                View screenshots
                            </button>
                        </div>
                    </div>
                    <div class="group cursor-pointer relative">
                        <LazyLoadImage
                            src={tanzanitelanding}
                            alt="Tanzniteplus landing page screenshot"
                            class="border-black border w-full h-56 object-cover rounded-lg transition-transform transform scale-100 group-hover:scale-105"
                        />
                        <div class="absolute inset-0 flex flex-col gap-2 items-center justify-center opacity-0 group-hover:opacity-100 transition-opacity">
                            <a href='https://www.tanzaniteplus.com/' class="bg-white text-gray-800 px-4 py-2 rounded-lg hover:bg-gray-200 transition-colors">
                                View live
                            </a>
                            <button class="bg-white text-gray-800 px-4 py-2 rounded-lg hover:bg-gray-200 transition-colors" onClick={() => setTanzaniteplusproject(true)}>
                                View screenshots
                            </button>
                        </div>
                    </div>
                    <div class="group cursor-pointer relative">
                        <LazyLoadImage
                            src={weddinglanding}
                            alt="wedding page landing screenshot"
                            class="border-black border w-full h-56 object-cover rounded-lg transition-transform transform scale-100 group-hover:scale-105"
                        />
                        <div class="absolute inset-0 flex flex-col gap-2 items-center justify-center opacity-0 group-hover:opacity-100 transition-opacity">
                            <a href='https://weddinginvitaion2.vercel.app/' class="bg-white text-gray-800 px-4 py-2 rounded-lg hover:bg-gray-200 transition-colors">
                                View live
                            </a>
                        </div>
                    </div> 

                </div>
                {/* gem experience project */}
                <div onMouseLeave={() => setGemproject(false)}>
                    <ReactModal isOpen={gemproject} contentLabel="Example Modal" onRequestClose={() => setGemproject(false)} className="h-full flex justify-center items-center">
                        <div className='relative flex-col w-3/4 bg-white overflow-scroll '>
                            <span onClick={() => setGemproject(false)}><CloseIcon className='absolute right-2 top-2 cursor-pointer'/></span>
                            <GallerySlider data={gemData} />
                        </div>
                    </ReactModal>
                </div>
                {/* nutritionistleena project  */}
                <div onMouseLeave={() => setNutritionistproject(false)}>
                    <ReactModal isOpen={nutritionistproject} contentLabel="Example Modal" onRequestClose={() => setNutritionistproject(false)} className="h-full flex justify-center items-center">
                        <div className='relative flex-col w-3/4  bg-white overflow-scroll '>
                        <span onClick={() => setNutritionistproject(false)}><CloseIcon className='absolute right-2 top-2 cursor-pointer'/></span>
                            <GallerySlider data={nutrifitData} />
                        </div>
                    </ReactModal>
                </div>
                {/* Tanzaniteplus project  */}
                <div onMouseLeave={() => setTanzaniteplusproject(false)}>
                    <ReactModal isOpen={Tanzaniteplusproject} contentLabel="Example Modal" onRequestClose={() => setTanzaniteplusproject(false)} className="h-full flex justify-center items-center">
                        <div className='relative flex-col w-3/4 bg-white overflow-scroll '>
                        <span onClick={() => setTanzaniteplusproject(false)}><CloseIcon className='absolute right-2 top-2 cursor-pointer'/></span>
                            <GallerySlider data={tanzaniteData} />
                        </div>
                    </ReactModal>
                </div>
                {/* clients portal */}
                <div onMouseLeave={() => setClientsproject(false)}>
                    <ReactModal isOpen={clientsproject} contentLabel="Example Modal" onRequestClose={() => setClientsproject(false)} className="h-full flex justify-center items-center">
                        <div className='relative flex-col w-3/4 bg-white overflow-scroll '>
                        <span onClick={() => setClientsproject(false)}><CloseIcon className='absolute right-2 top-2 cursor-pointer'/></span>
                            <GallerySlider data={clientsData} />
                        </div>
                    </ReactModal>
                </div>

            </div>
        </>
    )
}
const GallerySlider = ({ data }) => {

    const captionStyle = {
        fontSize: '2em',
        position: 'absolute',
        top: -60,
        color: "black",
        // fontWeight: 'bold',
    }

    return (
        <div className='pt-8'>
            {/* <h2 className='text-3xl md:text-5xl text-center text-black pt-16'>Gem Experience Icons</h2>
            <p className='text-2xl md:text-3xl text-center text-gray-500 py-5 md:py-10'>Featured Product</p> */}
            <Carousel
                data={data}
                time={3000}
                width="850px"
                height="50vh"
                captionStyle={captionStyle}
                radius="10px"
                captionPosition="bottom"
                automatic={true}
                slideBackgroundColor="#dadada"
                slideImageFit="contain"
                thumbnails={true}
                thumbnailWidth="70px"
                style={{
                    textAlign: "center",
                    maxWidth: "850px",
                    margin: "40px auto",
                }}
            />
        </div>
    )
}

export default Projects
