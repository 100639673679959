import React, { Component } from "react";
import "./error.css";

export default class extends Component {
    render() {
        return (
            <div className="mainContainer h-screen flex justify-center items-center">
                    <div id="error-box" className="absolute">
                        <div class="dot"></div>
                        <div class="dot two"></div>
                        <div class="face2">
                            <div class="eye"></div>
                            <div class="eye right"></div>
                            <div class="mouth sad"></div>
                        </div>
                        <div class="shadow move"></div>
                        <div class="message"><h1 class="h1 alert">Error!</h1><p className="p text">Ohhhh no, something went wrong.<br/> This Page Doesn't exist.</p></div>
                        <a href="/"><button class="button-box"><h1 class=" text-black">Go Back</h1></button></a>
                    </div>
                </div>
        )
    }
}