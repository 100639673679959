import React, { useEffect, useState } from "react";
import "./landing.css";
import photo from "../../Assets/navPagePhoto.png";
import img1 from "../../Assets/SVGIcons/Firebase-Dark.svg"
import img2 from "../../Assets/SVGIcons/Github-Dark.svg"
import img3 from "../../Assets/SVGIcons/MySQL-Dark.svg"
import img4 from "../../Assets/SVGIcons/PostgreSQL-Dark.svg"
import img5 from "../../Assets/SVGIcons/adobe-illustrator.svg"
import img6 from "../../Assets/SVGIcons/adobe-photoshop.svg"
import img7 from "../../Assets/SVGIcons/adobe-premiere-pro.svg"
import img8 from "../../Assets/SVGIcons/css3.svg"
import img9 from "../../Assets/SVGIcons/dart.svg"
import img10 from "../../Assets/SVGIcons/django.svg"
import img11 from "../../Assets/SVGIcons/figma.svg"
import img12 from "../../Assets/SVGIcons/flutter.svg"
import img13 from "../../Assets/SVGIcons/html-5.svg"
import img14 from "../../Assets/SVGIcons/javascript.svg"
import img15 from "../../Assets/SVGIcons/python.svg"


export default function Landing() {
    // shows typewriter effect 
    const [index, setIndex] = useState(0);
    const roles = ["Developer", "Designer", "Freelancer"];
    const [currentRole, setCurrentRole] = useState("");
    const [isDeleting, setIsDeleting] = useState(false);
    useEffect(() => {
        const updateRole = () => {
            const role = roles[index];
            const length = currentRole.length;
            
            if (isDeleting) {
                setCurrentRole(role.substring(0, length - 1));
            } else {
                setCurrentRole(role.substring(0, length + 1));
            }
            
            if (!isDeleting && currentRole === role) {
                setIsDeleting(true);
            } else if (isDeleting && currentRole === "") {
                setIsDeleting(false);
                setIndex((index + 1) % roles.length);
            }
        };
        const interval = setInterval(updateRole, 150); // Adjust speed here
        
        return () => {
            clearInterval(interval);
        };
    }, [index, isDeleting, currentRole]);
    

    return (
        <div className="landing sm:flex" id="landing" >
            <div className="sm:w-1/2 pl-6 sm:h-screen flex items-center">
                <div className="pl-5 sm:pl-14 pt-20">
                    <p className="sm:text-2xl font-poppin sm:tracking-tight sm:font-bold text-xl">Hey, This is</p>
                    <h2 className="sm:text-5xl font-extrabold sm:py-2 text-3xl">Shubham verma</h2>
                    <div className="sm:text-2xl sm:font-medium sm:tracking-wide">I'm a &nbsp;
                        <span className="typewrite text-4xl font-extrabold text-lime-500">
                            <span className="wrap">{currentRole}</span>
                        </span></div>
                </div>
            </div>

            <div className="sm:w-1/2 sm:h-screen right flex items-center justify-center">
                <div className="w-80"><img src={photo} alt="" className="w-max text-center p-6 " /></div>
                <div hidden class="circle lg:block">
                    <span className='circlespan' style={{ '--i': 1 }}><img src={img1}  alt="" className='img' /></span>
                    <span className='circlespan' style={{ '--i': 2 }}><img src={img2}  alt="" className='img' /></span>
                    <span className='circlespan' style={{ '--i': 3 }}><img src={img3}  alt="" className='img' /></span>
                    <span className='circlespan' style={{ '--i': 4 }}><img src={img4}  alt="" className='img' /></span>
                    <span className='circlespan' style={{ '--i': 5 }}><img src={img5}  alt="" className='img' /></span>
                    <span className='circlespan' style={{ '--i': 6 }}><img src={img6}  alt="" className='img' /></span>
                    <span className='circlespan' style={{ '--i': 7 }}><img src={img7}  alt="" className='img' /></span>
                    <span className='circlespan' style={{ '--i': 8 }}><img src={img8}  alt="" className='img' /></span>
                    <span className='circlespan' style={{ '--i': 9 }}><img src={img9}  alt="" className='img' /></span>
                    <span className='circlespan' style={{ '--i': 10 }}><img src={img10} alt=""  className='img' /></span>
                    <span className='circlespan' style={{ '--i': 11 }}><img src={img11} alt=""  className='img' /></span>
                    <span className='circlespan' style={{ '--i': 12 }}><img src={img12} alt=""  className='img' /></span>
                    <span className='circlespan' style={{ '--i': 13 }}><img src={img13} alt=""  className='img' /></span>
                    <span className='circlespan' style={{ '--i': 14 }}><img src={img14} alt=""  className='img' /></span>
                    <span className='circlespan' style={{ '--i': 15 }}><img src={img15} alt=""  className='img' /></span>
                </div>
            </div>
        </div>
    )
}