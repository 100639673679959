import { gsap } from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'
import React from 'react'
import { useEffect } from 'react'

const Gsap = () => {
    gsap.registerPlugin(ScrollTrigger);

    useEffect(() => {
        var tl =gsap.timeline();
    // navbar 
        gsap.from("#header", {
            y: 20,
            opacity: 0,
            duration: 2
        })
        gsap.to("#header", {
            y: 0,
            opacity: 1,
            duration: 2
        })
    // landingpage
        gsap.from("#landing", {
            opacity: 0,
            duration: 3,
            delay: .5
        })
        gsap.to("#landing", {
            opacity: 1,
            duration: 3,
            delay: .5
        })
    // about page  
        gsap.from("#aboutPageImage",{
            opacity:0,
            x:-80,
            duration:3,
            delay:1
        })
        gsap.to("#aboutPageImage",{
            scrollTrigger:{
                trigger:"#aboutPageImage",
                scroller:"body",
                // markers:true,
                start:"top 80%",
                end:"top 50%",
                scrub:2
        },
            opacity:1,
            x:0,
            // duration:3,
            // delay:1
        })
        // about page 
        gsap.from("#aboutPageText",{
            x:80,
            opacity:0,
            duration:3,
            delay:1
        })
        gsap.to("#aboutPageText",{
            scrollTrigger:{
                trigger:"#aboutPageText",
                scroller:"body",
                // markers:true,
                // start:"top 80%",
                // end:"bottom %",
                scrub:2
        },
            x:0,
            opacity:1,
            // duration:3,
            // delay:1
        })
        // qualification page 
        gsap.from("#qual-heading",{
            x:80,
            opacity:0
        })
        gsap.to("#qual-heading",{
            scrollTrigger:{
                trigger:"#qual-heading",
                scroller:"body",
                // markers:true,
                // start:"top 80%",
                // end:"bottom %",
                scrub:2
        },
            x:0,
            opacity:1
        })
        // eduction page 
        gsap.from("#education",{
            opacity:0,
            scale:1.5
        })
        gsap.to("#education",{
            scrollTrigger:{
                trigger:"#qual-heading",
                scroller:"body",
                // markers:true,
                // start:"top 80%",
                // end:"bottom %",
                scrub:2
        },
            opacity:1,
            scale:1
        })
        // services page 
        gsap.from("#servivesBoxes",{
            x:40,
            opacity:0
        })
        gsap.to("#servivesBoxes",{
            scrollTrigger:{
                trigger:"#servivesBoxes",
                scroller:"body",
                scrub:4,
                // markers:true,
                // start:"top 80%",
                end:"bottom 60%",
        },
            x:0,

            opacity:1
        })
    }, [])
}

export default Gsap;