import React, { useEffect, useState } from "react";
import "../../style.css";
import Landing from "../Landing/Landing";
import About from "../About/About";
import Qualification from "../Qualification/Qualification";
import Services from "../Services/Services";
import Footer from "../../Component/Footer/Footer";
import Navbar from "../../Component/Navbar/Navbar";
import ScrollToTop from "react-scroll-to-top";
import { ProjectSlider } from "../../Component/ProjectSlider/ProjectSlider";
import Gsap from "../../Component/Gsap/gsap";
import Loader from "../../Component/Loader/Loader";

export default function Mainpage() {
    const [loader, setLoader] = useState(true);

    useEffect(() => {
        setTimeout(() => { setLoader(false) }, 3000);
    }, [])

    return (<>
        {loader === false ? (
            <main className="main">
                <Gsap />
                <link href='https://unpkg.com/boxicons@2.1.1/css/boxicons.min.css' rel='stylesheet' />
                <ScrollToTop id="scrolltotop" className="pl-2" width="24"  top="300" height="18" color="green" />
                <div className="fixed w-screen z-10" id="header"><Navbar /></div>
                <Landing />
                <About />
                <Qualification />
                <Services />
                <ProjectSlider />
                <Footer />
            </main>
        ) : (
            <Loader />
        )}
    </>
    )
}