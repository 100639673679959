import React from 'react'
import "./test.css"
import CallIcon from '@mui/icons-material/Call';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import WatchLaterIcon from '@mui/icons-material/WatchLater';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import EmailIcon from '@mui/icons-material/Email';

export const Test = () => {
  return (
    <div className='contactMain'>
      <h1 className='text-center text-3xl font-medium tracking-widest pt-4'>CONTACT ME</h1>
      <div className='sm:flex p-10'>
        <div className="left bg-slate-400  sm:w-1/2 p-8">
          <div className="callus m-6 space-y-2">
            <div className='text-3xl font-medium'><span className='text-orange-500'><CallIcon/></span> Call Me</div>
            <div className=''>+91 8239478110</div>
          </div>
          <div className="location m-6 space-y-2">
            <div className='text-3xl font-medium'><span className='text-orange-500'><LocationOnIcon/></span> Location</div>
            <div className=''>Jaipur, Rajasthan, India (302020) </div>
          </div>
          <div className="availibility m-6 space-y-2">
            <div className='text-3xl font-medium'><span className='text-orange-500'><WatchLaterIcon /></span> Business hours</div>
            <div className=''>Mon-Sat... 10am-8pm <br /> Sunday closed</div>
          </div>
          <div className="socialicons flex justify-center gap-4 pt-6">
            <div className=''><a className='text-black hover:text-green-500' href='https://wa.me/9982561300'><WhatsAppIcon sx={{ fontSize: "40px" }} /></a></div>
            <div className=''><a className='text-black hover:text-sky-500' href="mailto:shubhamverma9982@gmail.com" ><EmailIcon sx={{ fontSize: "40px" }} /></a></div>
          </div>
        </div>

        <div className="right bg-slate-300 sm:w-3/4 ">
          <form action="#">
            <div class="p-4" >
              <div className='w-full sm:flex gap-3 space-y-2 pr-3' >
                <div></div>
                <input type="text" placeholder="Name" className='w-1/2' />
                <input type="email" placeholder="Email" className='w-1/2' />
              </div>
              <div className='w-full sm:flex gap-3 space-y-3 pr-3' >
                <div></div>
                <input type="text" placeholder="Address" className='w-1/2' />
                <input type="tel" placeholder="Phone/Skype" className='w-1/2' />
              </div>
              <div className='w-full sm:flex pt-3 pr-3 sm:pl-3'>
                <textarea className='contacttextarea w-full' placeholder="About Your Project" cols="30" rows="10"></textarea>

              </div>

            </div>
            <input className='ml-8 mb-3' type="submit" value="Submit" />
          </form>

        </div>
      </div>
    </div>
  )
}

