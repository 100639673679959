import React, { Component, Suspense } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Mainpage from "./Conatiner/Mainpage/Mainpage";
import Error from "./Component/Error/Error";
import { Test } from "./Conatiner/Test/Test";
import Projects from "./Component/ProjectSlider/Projects";

export default class extends Component {
    render() {
        return (
            <Suspense fallback="loading">
                <BrowserRouter>
                    <Routes>
                        <Route element={<Mainpage />} path="/" />
                        <Route element={<Projects />} path="/projects"/>
                        <Route element={<Error />} path="*" />
                        <Route element={<Test />} path="test" />
                    </Routes>
                </BrowserRouter>
            </Suspense>
        )
    }
}
