import React, { useState } from 'react';
import "./portal.css";
import ReactModal from 'react-modal';
import rotate from "../../Assets/rotate.gif";
import { PortalVersionComponent } from './PortalVersionComponent';

export default function OpenPortal() {
    const [isOpen, setIsOpen] = useState(false);

    const hideScrollAndNavbar = () => {
        document.getElementById('scrolltotop').style.display = "none";
        document.getElementById('navbar').style.display = "none";
    }
    const showScrollAndNavbar = () => {
        setIsOpen(false)
        document.getElementById('scrolltotop').style.display = "block";
        document.getElementById('navbar').style.display = "block";

    }
    return (
        <div>
            <button onClick={() => setIsOpen(true)} className='text-white'>
                <img src={rotate} className='towerPortal w-24' onClick={()=>hideScrollAndNavbar()} />
            </button>
            <ReactModal isOpen={isOpen} contentLabel="Example Modal" onRequestClose={() => setIsOpen(false)} className="z-20 absolute h-full w-full">
                <main className='portlMain'>
                    <div className='text-5xl text-right text-white pr-5 pt-3 cursor-pointer' onClick={() => showScrollAndNavbar()}>&#10007;</div>
                    <div className='portalDiv'></div>
                    <div className='portalDiv'></div>
                    <div className='portalDiv'></div>
                    <div className='portalDiv'></div>
                    <div className='portalDiv'></div>
                    <div className='versionComponent'><PortalVersionComponent /></div>
                </main>
            </ReactModal>
        </div>
    )
}
