import React from "react";
import "./navbar.css";
import resume from "../../Assets/shubham_verma_Resume.pdf";
import logo from "../../Assets/logo.png";
import ToggleTheme from "../ToggleTheme/ToggleTheme";

export default function Navbar() {
    function toggle() {
        document.getElementById('dropdown_menu').classList.toggle("open");
    }
    function closenav () {
        document.getElementById('dropdown_menu').classList.toggle("open");
        document.getElementById('checkbox').checked = "";
    }
    return (
        <header className="headerclass" id="navbar">
            <link href='https://unpkg.com/boxicons@2.1.1/css/boxicons.min.css' rel='stylesheet' />
            <div className="navbar">
                <div className="logo  sm:pl-5"><img className="logoimg sm:hidden m-2 h-14" src={logo} /><a className="navlogo tracking-tighter hidden sm:block" href=""><span className="text-5xl">S</span>HUBH</a></div>
                <ul className="links text-xs md:text-lg font-medium">
                    <li><a href="#landing" className="navText"><span className="font-bold text-xl">H</span>ome</a></li>
                    <li><a href="#about" className="navText"><span className="font-bold text-xl">A</span>bout</a></li>
                    <li><a href="#qualification" className="navText"><span className="font-bold text-xl">Q</span>ualification</a></li>
                    <li><a href="#services" className="navText"><span className="font-bold text-xl">S</span>ervices</a></li>
                    <li><a href={resume} title="Download Resume">&#128196;</a></li>
                    {/* <li><a href={resume} className="action_btn">Download CV</a></li> */}
                </ul>
                <div className="flex gap-3">
                    <ToggleTheme />
                    <div className="toggle_btn" id="toggle_btn" >
                        <input type="checkbox" id="checkbox"  />
                        <label for="checkbox" className="toggle"  onClick={() => toggle()}>
                            <div className="bars" id="bar1"></div>
                            <div className="bars" id="bar2"></div>
                            <div className="bars" id="bar3"></div>
                        </label>
                    </div>
                </div>

            </div>
            <div className="dropdown_menu " id="dropdown_menu"onClickCapture={()=>closenav()} >
                <li><a href="#landing" className="navText" >Home</a></li>
                <li><a href="#about" className="navText" >About</a></li>
                <li><a href="#qualification" className="navText" >Qualification</a></li>
                <li><a href="#services" className="navText" >Services</a></li>
                <li><a href={resume} download="Shubham_Resume" className="action_btn" >Download CV</a></li>
            </div>
        </header>
    )
}