import React from "react";
import "./loader.css";

export default function Loader() {
    return (
       <div className="mainLoader">
         <div class="loader">
            <div class="loaderCircle"></div>
            <div class="loaderCircle"></div>
            <div class="loaderCircle"></div>
            <div class="loaderShadow"></div>
            <div class="loaderShadow"></div>
            <div class="loaderShadow"></div>
        </div>
       </div>
    )
}