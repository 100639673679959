import React from 'react';
import "./portal.css";

export const PortalVersionComponent = () => {
    
    return (
        <div className='verionBody text-center'>
            <span className='versionText'>Looking for diffrent site?</span><br />
            <span className='versionText'>Going back to past...</span>
            <a href='https://shubhverma.netlify.app/' target='blank'>
                <div class="versionCard sm:h-[285px] sm:w-[195px] ">
                    <div class="textBox">
                        <p class="text head">v1</p>
                    </div>
                </div>
            </a>
        </div>
    )
}
