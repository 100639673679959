import React from "react";
import "./about.css";
import aboutImg from "../../Assets/about.png";

export default function About() {

    return (
        <div className="about sm:flex pt-10" id="about">
            <link href='https://unpkg.com/boxicons@2.1.1/css/boxicons.min.css' rel='stylesheet' />
            <div className="left justify-center hidden sm:block " id="aboutPageImage">
                <img src={aboutImg} className="p-6" />
            </div>
            <div className="right sm:w-4/5 p-4 sm:py-14" id="aboutPageText">
                <div><h1 className="text-4xl font-bold  text-yellow-500 my-6 text-center sm:text-left ">About Me</h1>
                    <p className="tracking-wider p-4">I'm Shubham, a passionate web developer with 1 year of professional experience. I specialize in front-end development and have a strong foundation in HTML, CSS, and JavaScript. I enjoy crafting responsive and user-friendly websites that deliver an exceptional user experience.
                        I am proficient in using popular front-end frameworks like React and have a good understanding of modern web development practices.
                    </p></div>
                    <h1 className="text-4xl font-bold  text-yellow-500 my-6 text-center sm:text-left" >Skills</h1>
                <div className="flex justify-center items-center">
                    <p className="w-2/3 flex flex-wrap justify-center items-center ">
                        <i></i>
                        <i class='HTML5 bx bxl-html5 bx-lg SkillIcon ' title="HTML5" ></i>
                        <i class='CSS3 bx bxl-css3 bx-lg SkillIcon ' title="CSS3" ></i>
                        <i class='JavaScript bx bxl-javascript bx-lg SkillIcon ' title="JavaScript" ></i>
                        <i class='react bx bxl-react bx-lg SkillIcon ' title="react" ></i>
                        <i class='BootStrap bx bxl-bootstrap bx-lg SkillIcon ' title="BootStrap" ></i>
                        <br />
                        <i class='Tailwind bx bxl-tailwind-css bx-lg SkillIcon ' title="Tailwind" ></i>
                        <i class='Git bx bxl-git bx-lg SkillIcon ' title="Git" ></i>
                        <i class='github bx bxl-github bx-lg SkillIcon ' title="github" ></i>
                        <i class='php bx bxl-php bx-lg SkillIcon ' title="php" ></i>
                        <i class='node bx bxl-nodejs bx-lg SkillIcon ' title="node" ></i>
                        <br />
                        <i class='WordPress bx bxl-wordpress bx-lg SkillIcon ' title="WordPress" ></i>
                        <i class='DataBases bx bxs-data bx-lg SkillIcon ' title="DataBases" ></i>
                        <i class='Python bx bxl-python bx-lg SkillIcon ' title="Python" ></i>
                        <i class='Adobe bx bxl-adobe bx-lg SkillIcon ' title="Adobe" ></i>
                        <i class='threeD bx bxs-component bx-lg SkillIcon ' title="3D" ></i>
                        <img src="https://api.iconify.design/devicon/nextjs.svg" height={40} width={40}/>
                    </p>
                </div>
            </div>
        </div>
    )
}