import React from "react";
import "./service.css";

export default function ServiceTemplate({ icon, heading, description }) {
    return (
        <div className="card">
            <link href='https://unpkg.com/boxicons@2.1.1/css/boxicons.min.css' rel='stylesheet' />
            <div className="icon">
                {icon}
            </div>
            <p className="title font-bold">{heading}</p>
            <p className="text text-xs">{description}</p>
        </div>
    )
}