import React from 'react'
import { SliderTemplate } from './sliderTemplate'
import "./slider.css"
import nutrifitlanding from "../../Assets/projects/nutri/landing.webp"
import tanzaitelanding from "../../Assets/projects/tanzanite/landing.webp"
import { LazyLoadImage } from 'react-lazy-load-image-component';


export const ProjectSlider = () => {
    return (
        <div className='slidermainBody'>
            <h1 className='text-center text-4xl font-extrabold text-orange-500 p-5'>Projects</h1>
            <div className='sliderBody'>
                <div className='flex justify-center items-center'>
                    <section class="carousel w-[90%] md:w-[70vw] min-h-[40vh] flex overflow-scroll">
                        {/* <ScrollingCarousel className=''>
                            <SliderTemplate img={certificateImg} trylink="https://birthcerifcateonline.000webhostapp.com/" codelink="https://github.com/shubhamverma9982/online-birth-certificate" />
                            <SliderTemplate img={certificateImg} trylink="https://birthcerifcateonline.000webhostapp.com/" codelink="https://github.com/shubhamverma9982/online-birth-certificate" />
                        </ScrollingCarousel> */}
                        <div className='p-8'>
                            <div class="slidercard">
                                <div class="sliderfirst-content">
                                    <LazyLoadImage src={tanzaitelanding} alt=''/>
                                </div>
                                <div class="slidersecond-content">
                                    <span>
                                        <a href="https://tanzaniteplus.com/">View Live</a> <br />
                                        {/* <a href="#">View Code</a><br /> */}
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div className='p-8'>
                            <div class="slidercard">
                                <div class="sliderfirst-content">
                                    <LazyLoadImage src={nutrifitlanding} alt=''/>
                                </div>
                                <div class="slidersecond-content">
                                    <span>
                                        <a href="https://nutritionistleena.vercel.app/">View Live</a> <br />
                                        {/* <a href="#">View Code</a><br /> */}
                                    </span>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
            </div>
            <div className='text-center p-4 underline'><a href='/projects' className='projectCodepenLink hover:text-blue-400'>view more projects</a></div>
        </div>
    )
}
