import React from "react";
import "./qual.css";

export default function Qualification() {
    const Education = () => {
        document.getElementById("work").style.display = "none";
        document.getElementById("education").style.display = "block";
        document.getElementById("btn1").style.color = "rgb(132 204 22)";

    }
    const Work = () => {
        document.getElementById("education").style.display = "none";
        document.getElementById("work").style.display = "block";
        document.getElementById("btn1").style.color = "var(--text-color)";

    }

    return (
        <div className="qualification-container py-6 pt-12" id="qualification">
            <link href='https://unpkg.com/boxicons@2.1.1/css/boxicons.min.css' rel='stylesheet' />
            <h1 className="qual-heading text-center text-4xl font-semibold tracking-wide text-cyan-500 pt-7" id="qual-heading">Qualification</h1>
            <span className="flex gap-8 justify-center pt-6" id="myDIV">
                <button id="btn1" className="focus:text-lime-500 text-lime-500 text-xl sm:text-2xl font-semibold tracking-wide" onClick={() => Education()}><i className="bx bxs-book-open"></i> Education</button>
                <button id="btn2" className="focus:text-lime-500 text-xl sm:text-2xl font-semibold tracking-wide" onClick={() => Work()}> Work <i className="bx bxs-briefcase-alt-2"></i></button>
            </span>

            <div id="education">
                <div class="educationContainer px-4">
                    <div class="timeline-block timeline-block-right">
                        <div class="marker"></div>
                        <div class="timeline-content">
                            <h3 className="text-lg font-medium tracking-wide">BCA </h3>
                            <span className="font-light ">Apex Institute of Management & Science, <br /> Jaipur</span>
                            <p className="text-sm py-3 italic font-poppin"><i className="bx bxs-calendar"></i> 2018 - 2021</p>
                        </div>
                    </div>

                    <div class="timeline-block timeline-block-left">
                        <div class="marker"></div>
                        <div class="timeline-content">
                            <h3 className="text-lg font-medium tracking-wide">Senior Secondary</h3>
                            <span className="font-light">Mount Mary School</span>
                            <p className="text-sm py-3 italic font-poppin"> 2018 - 2017 <i className="bx bxs-calendar"></i> </p>
                        </div>
                    </div>

                    <div class="timeline-block timeline-block-right">
                        <div class="marker"></div>
                        <div class="timeline-content">
                            <h3 className="text-lg font-medium tracking-wide">Secondary</h3>
                            <span className="font-light">Mount Mary School</span>
                            <p className="text-sm py-3 italic font-poppin"><i className="bx bxs-calendar"></i> 2015 - 2016</p>
                        </div>
                    </div>
                    <div class="timeline-block timeline-block-left">
                        <div class="marker"></div>
                    </div>
                </div>

            </div>
            <div id="work" hidden>
                <div class="educationContainer px-4">
                    <div class="timeline-block timeline-block-left">
                        <div class="marker"></div>
                        <div class="timeline-content">
                            <h3 className="text-lg font-medium tracking-wide"><a href="https://www.gem-experience.com/" target="blank">Gem Experience</a></h3>
                            <span className="font-light italic">&#40; Senior Web Developer &#41;<br /> Jaipur</span>
                            <p className="text-sm py-3 italic font-poppin"> September 2023 - Present <i className="bx bxs-calendar"></i> </p>
                        </div>
                    </div>

                    <div class="timeline-block timeline-block-right">
                        <div class="marker"></div>
                        <div class="timeline-content">
                            <h3 className="text-lg font-medium tracking-wide"><a href="https://www.ninealgo.com/" target="blank">Ninealgo Technologies Pvt Ltd</a></h3>
                            <span className="font-light italic">&#40; Asst. Web Developer &#41; <br /> Jaipur</span>
                            <p className="text-sm py-3 italic font-poppin"><i className="bx bxs-calendar"></i> April 2022 - April 2023</p>
                        </div>
                    </div>

                    <div class="timeline-block timeline-block-left">
                        <div class="marker"></div>
                        <div class="timeline-content">
                            <h3 className="text-lg font-medium tracking-wide"><a href="#">Think and Learn pvt. ltd</a></h3>
                            <span className="font-light italic">&#40; Bussiness Development Associate  &#41;<br /> Jaipur</span>
                            <p className="text-sm py-3 italic font-poppin"> February 2022 - April 2022 <i className="bx bxs-calendar"></i> </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}