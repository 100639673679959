import React from "react";
import ServiceTemplate from "./servicetemplate";

export default function Services() {
    return (
        <div className="services pt-12" id="services">
            <h1 className="text-center text-4xl font-extrabold text-pink-500 p-5">My Services</h1>
            <div className="justify-around gap-3 items-center sm:flex sm:p-6 p-4" id="servivesBoxes">
                <div className="flex justify-center"><ServiceTemplate icon={<i className="bx bx-code-alt bx-lg"></i>} heading="Web Developement" description="Make design easy so you can focus on building your business.focuses on creating intuitive and visually appealing interfaces." /></div>
                <div className="flex justify-center"><ServiceTemplate icon={<i className="bx bx-mobile-alt bx-lg"></i>} heading="App Developement" description="Web Development and Interactive Pages, Make development easy so you can focus on building your business." /></div>
                <div className="flex justify-center"><ServiceTemplate icon={<i className="bx bx-happy-heart-eyes bx-lg"></i>} heading="UI/UX Design" description="I design intuitive and visually appealing user interfaces (UI) and user experiences (UX) to enhance website usability and engagement" /></div>
            </div>
        </div>
    )
}